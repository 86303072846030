<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                        <b-col sm="6">
                            <ValidationProvider name="Select Category" vid="category_id" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Category <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="categoryList"
                                    id="category_id"
                                    rows="6"
                                    v-model="formData.category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Select sub Category" vid="sub_category_id" rules="">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select sub Category <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="subCategoryList"
                                    id="sub_category_id"
                                    rows="6"
                                    v-model="formData.sub_category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="12">
                            <ValidationProvider name="Title" vid="title" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Title <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="title"
                                    rows="6"
                                    v-model="formData.title"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Image" vid="image" rules="">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Image <span class="text-danger">*</span>
                                </template>
                                <b-form-file
                                    @change="handleImg"
                                    id="image"
                                    rows="6"
                                    v-model="formData.image"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <b-form-group label="Question Type" v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    v-model="formData.type"
                                    :options="[
                                        {
                                            value: 1,
                                            text: 'Options'
                                        },
                                        {
                                            value: 2,
                                            text: 'True/False'
                                        },
                                    ]"
                                    :aria-describedby="ariaDescribedby"
                                    @change="setValueOptions"
                                    name="Question Type"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <h5>Options</h5>
                        </b-col>
                        <slot v-if="formData.type == 1">
                            <b-col sm="6">
                            <ValidationProvider name="Option (1)" vid="option1" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (1) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option1"
                                    rows="6"
                                    v-model="formData.options.option1"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Option (2)" vid="option2" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (2) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option2"
                                    rows="6"
                                    v-model="formData.options.option2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Option (3)" vid="option3" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (3) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option3"
                                    rows="6"
                                    v-model="formData.options.option3"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Option (4)" vid="option4" rules="">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (4) 
                                </template>
                                <b-form-input
                                    id="option4"
                                    rows="6"
                                    v-model="formData.options.option4"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        </slot>
                        <slot v-if="formData.type == 2">
                            <b-col sm="6">
                            <ValidationProvider name="Option (1)" vid="option1" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (1) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option1"
                                    rows="6"
                                    v-model="formData.options.option1"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                                <ValidationProvider name="Option (2)" vid="option2" rules="required">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="formData"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    Option (2) <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="option2"
                                        rows="6"
                                        v-model="formData.options.option2"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </slot>
                        <b-col sm="6">
                            <ValidationProvider name="Result" vid="result" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Result <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="optionList"
                                    id="result"
                                    rows="6"
                                    v-model="formData.result"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Close</b-button>
                        </div>
                    </div>
                </b-form>
            </ValidationObserver>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      if (this.id) {
          this.formData = this.getItem()
      }
  },
  data () {
    return {
      saveBtnName: this.id ? 'Update' : 'Save',
      formData: {
        title: '',
        category_id: '',
        sub_category_id: '',
        image: [],
        type: 1,
        options: {
            option1: null,
            option2: null,
            option3: null,
            option4: null,
        }
      }
    }
  },
  computed: {
      categoryList () {
        return this.$store.state.commonObj.quizCategoryList
      },
      subCategoryList () {
        return this.$store.state.commonObj.subCategoryList
      },
      optionList () {
        return this.$store.state.commonObj.optionList
      },
      loading () {
        return this.$store.state.static.loading
      }
  },
  methods: {
    setValueOptions (event) {
        this.formData.options.option1 = ''
        this.formData.options.option2 = ''
        if (event == 2) {
            this.formData.options.option1 = 'yes'
            this.formData.options.option2 = 'No'
        }

    },
    handleImg( event ){
        this.formData.image = event.target.files[0];
    },
    getItem () {
        const item = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify(item))
    },
    async register () {
        this.$store.dispatch('mutedLoad', { loading: true, listReload: false })

        let result = null
        let formData = new FormData()

        Object.keys(this.formData).map(key => {
            if(key == 'options') {
                formData.append(key, JSON.stringify(this.formData[key]))
            } else {
                formData.append(key, this.formData[key])
            }
        })

        if (this.id) {
            formData.append('_method', 'put')
            result = await RestApi.postData(baseUrl, `${'api/quizs/update'}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/quizs/store', formData)
        }
        
        this.$store.dispatch('mutedLoad', { loading: false, listReload: true })
        this.$store.dispatch('dropdownLoad', { hasDropdownLoaded: false })
        if (result.success) {
            if (!this.id) {
                this.formData.content = ''
            }
            iziToast.success({
                title: 'Success',
                message: result.message
            })
             this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
